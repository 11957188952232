<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th style="width: 75px">
              <SelectWarehouse
                :label="$t('labels.warehouse_1')"
                :placeholder="$t('labels.warehouse_1')"
                name="id_warehouse"
                sort-name="warehouse_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :auto-select="false"
              />
            </th>
            <th style="width: 75px">
              <SelectPos
                :label="$t('labels.pos')"
                :placeholder="$t('labels.pos')"
                name="id_pos"
                sort-name="pos_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :auto-select="false"
              />
            </th>
            <th style="width: 75px">
              <InputFilter
                :label="$t('labels.sku')"
                :placeholder="$t('labels.sku')"
                name="sku"
                sort-name="sku"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 135px">
              <DateRangeFilter
                :label="$t('labels.from_to')"
                :placeholder="$t('labels.from_to')"
                :default-value="filters.data_date"
                name="data_date"
                sort-name="data_date"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 135px">
              <InputFilter
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                sort-name="customer_goods_barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 135px">
              <InputFilter
                :label="$t('labels.category')"
                :placeholder="$t('labels.category')"
                name="category_name"
                sort-name="category_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 135px">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 135px">
              <InputFilter
                :label="$t('labels.goods_description')"
                :placeholder="$t('labels.goods_description')"
                name="description"
                sort-name="description"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 135px">
              <InputFilter
                :label="$t('labels.size')"
                :placeholder="$t('labels.size')"
                name="size"
                sort-name="size"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 90px">
              <InputFilterFromTo
                :label="$t('labels.total_in_order')"
                :placeholder="$t('labels.total_in_order')"
                name="total_in_order"
                sort-name="total_in_order"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 90px">
              <InputFilterFromTo
                :label="$t('labels.total_handover')"
                :placeholder="$t('labels.total_handover')"
                name="total_handover"
                sort-name="total_handover"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center c-fb0000" style="width: 90px">
              {{ $t("labels.rate") }}
            </th>
            <th style="width: 120px">
              <SelectFilter
                :label="$t('labels.forecast_type')"
                :placeholder="$t('labels.forecast_type')"
                name="forecast_out_of_stock"
                sort-name="forecast_out_of_stock"
                :options="forecastOutOfStockOptions"
                :default-value="filters.forecast_out_of_stock"
                :clearable="false"
                class="input-purple"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 120px">
              <SelectFilter
                :label="$t('labels.forecast_period')"
                :placeholder="$t('labels.forecast_period')"
                name="most_recent_period"
                sort-name="most_recent_period"
                :options="periodOptions"
                :default-value="filters.most_recent_period"
                :clearable="false"
                class="input-purple"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 100px">
              <InputFilterFromTo
                :label="`${$t('labels.available_quantity_1')} [A]`"
                :placeholder="`${$t('labels.available_quantity_1')} [A]`"
                name="available"
                sort-name="available"
                :sorting="filters.sort_by"
                custom-class="input-purple"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center purple--text">
              {{ $t("labels.quantity") }} <br />
              [B]
            </th>
            <th class="text-center purple--text">
              {{ $t("labels.forecast_out_of_stock_1") }} <br />
              [C=A/B]
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            :key="`${item.id}_${item.id_warehouse}_${item.id_pos}`"
            class="text-center"
          >
            <td>{{ item.warehouse_code }}</td>
            <td>{{ item.pos_code }}</td>
            <td>{{ item.sku }}</td>
            <td>
              {{ formatDateTime(item.start_time, "DD/MM/YYYY") }} -
              {{ formatDateTime(item.end_time, "DD/MM/YYYY") }}
            </td>
            <td>
              <span
                class="cursor-pointer primary--text text-decoration-underline"
                @click="showDetailDialog(item)"
              >
                {{ item.customer_goods_barcode }}
              </span>
              <v-icon
                color="warning"
                class="ml-1 cursor-pointer"
                small
                @click="showChartDialog(item)"
                >mdi-chart-line</v-icon
              >
            </td>
            <td>{{ item.category_name }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.size }}</td>
            <td>{{ formatNumber(item.total_in_order) }}</td>
            <td>{{ formatNumber(item.total_handover) }}</td>
            <td>{{ item.ratio }}</td>
            <td>
              {{ $t(`labels.forecast_type_${item.forecast_type}`) }}
            </td>
            <td>
              {{
                $t(`labels.last_number_days`, { number: item.forecast_period })
              }}
            </td>
            <td>{{ formatNumber(item.available) }}</td>
            <td>
              {{
                item.quantity_by_type ? formatNumber(item.quantity_by_type) : ""
              }}
            </td>
            <td>{{ item.forecast }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" md="4">
        <v-btn color="info" @click="downloadExcel" class="mr-1">
          {{ $t("labels.excel") }}
        </v-btn>

        <v-btn color="success" @click="showChartDialog({})" class="mr-1">
          {{ $t("labels.summary_chart") }}
        </v-btn>

        <v-btn
          color="warning"
          v-if="checkPermission(['goods_download_excel_handover'])"
          @click="downloadExcelHandover"
        >
          {{ $t("labels.order_status_200") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="4">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="12" md="4" class="text-right">
        <v-btn
          :color="
            checkPermission(['email_goods_report_summary']) ? 'info' : 'default'
          "
          @click="downloadExcelSummary"
          class="mr-2"
        >
          <v-icon>mdi-email-fast</v-icon> {{ $t("labels.email_goods_value") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="detailDialog" max-width="1024px">
      <ReportDetail
        v-if="detailDialog"
        :item="detailItem"
        @cancel="hideDetailDialog"
      />
    </v-dialog>

    <v-dialog v-model="chartDialog" max-width="1024px">
      <ReportChart
        v-if="chartDialog"
        :item="detailItem"
        :dates="filters.data_date"
        @cancel="hideChartDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import { PERIOD_OPTIONS, FORECAST_TYPE_OPTIONS } from "@/libs/const";
import moment from "moment/moment";
const DEFAULT_DATES = [
  moment().subtract("30", "days").format("YYYY-MM-DD"),
  moment().format("YYYY-MM-DD"),
];

export default {
  name: "ReportList",
  components: {
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectPos: () => import("@/components/table/SelectPos"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    ReportDetail: () => import("@/components/goods/ReportDetail"),
    ReportChart: () => import("@/components/goods/ReportChart"),
  },
  data: () => ({
    isLoading: false,
    items: [],
    sum: {},
    page: 1,
    total: 0,
    totalPage: 1,
    viewOption: "chart",
    chartColumns: [],
    filters: {
      sort_by: "available",
      sort_type: "desc",
    },
    detailItem: {},
    detailDialog: false,
    chartDialog: false,
    periodOptions: [...PERIOD_OPTIONS],
    forecastOutOfStockOptions: [...FORECAST_TYPE_OPTIONS],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.items = [];
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.filters = {
      ...this.filters,
      data_date: [...DEFAULT_DATES],
      forecast_out_of_stock: 1,
      most_recent_period: 30,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    showDetailDialog(item) {
      this.detailItem = { ...item };
      this.detailDialog = true;
    },
    hideDetailDialog() {
      this.detailItem = {};
      this.detailDialog = false;
    },
    showChartDialog(item) {
      this.detailItem = { ...item };
      this.chartDialog = true;
    },
    hideChartDialog() {
      this.detailItem = {};
      this.chartDialog = false;
    },
    getList: debounce(function () {
      httpClient
        .post("/goods-report-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.total = data.total;
          this.sum = { ...data.sum };
          this.items = [...data.rows].map((item) => {
            return {
              ...item,
              total_forecast_handover: +item.total_forecast_handover,
              total_forecast_order: +item.total_forecast_order,
              total_handover: +item.total_handover,
              total_in_order: +item.total_in_order,
            };
          });
        });
    }, 1000),

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "toc-do-voi-hang.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-report-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.report_sending"));
      } catch (e) {
        this.isLoading = false;
      }
    },

    async downloadExcelHandover() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "ban-giao.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-report-export-handover",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.report_sending"));
      } catch (e) {
        this.isLoading = false;
      }
    },

    async downloadExcelSummary() {
      if (!this.checkPermission(["email_goods_report_summary"])) {
        this.$vToastify.error(
          this.$t("messages.email_goods_value_permission_denied")
        );
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-report-export-summary", {
          ...this.filters,
          page: this.page,
        });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>
